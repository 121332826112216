/* https://codepen.io/maheshambure21/pen/MWWgyyG */

@import url('https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap');
/* Used Google Fonts */
font-family: 'Roboto', sans-serif;
font-family: 'Mansalva', cursive;
font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Lora', serif;
font-family: 'Muli', sans-serif;
font-family: 'Lobster', cursive;
font-family: 'Cinzel', serif;
font-family: 'Bangers', cursive;
/* Used Google Fonts */
*{
  margin:0;
  padding:0;
}
body{
  font-size:17px;
  color:#424242;
  font-family: 'Open Sans', sans-serif;
  background-color: #ffffff;
  background-image: url("https://www.transparenttextures.com/patterns/clean-gray-paper.png");
}
h1, h2, h3, h4, h5, h6, p{
  margin:0px;
  padding:0px;
}
.logo-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display:inline-block;
}
.logo-container ul li {
    width: 300px;
    height: 120px;
    background: #fff;
    border-radius: 10px;
    margin: 10px;
    float: left;
    padding:20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);    
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-container ul li a{
  text-decoration:none !important;
  display: inline-block;
}
.logo-holder{
  text-align:center;
}
/* Logo-1 */
.logo-1 h3 {
    color: #e74c3c;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 50px;
    line-height:1.3;
}
.logo-1 p {
    font-size: 14px;
    letter-spacing: 8px;
    text-transform: uppercase;
    padding-left: 10px;
    color: #34495e;
    font-weight: 600;
}
/* Logo-2 */
.logo-2 h3 {
    color: #e74c3c;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 50px;
    text-transform: uppercase;
    line-height:1;
}
.logo-2 p {
    font-size: 14px;
    padding-left: 0px;
    color: #34495e;
    font-weight: 600;  
    text-transform: uppercase;
}
/* Logo-3 */
.logo-3 h3 {
    color: #e74c3c;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 50px;
    line-height:1.3;
}
.logo-3 p {
    font-size: 14px;
    letter-spacing: 7px;
    text-transform: uppercase;
    background: #34495e;
    font-weight: 400;
    color: #fff;
    padding-left: 5px;
}
/* Logo-4 */
.logo-4 h3 {
    color: #e74c3c;
    font-weight: 300;
    font-size: 50px;
    line-height: 0.65;
    font-family: 'Lobster', cursive;
}
.logo-4 p {
    font-size: 14px;
    margin-left: 50px;
    color: #545454;
    font-weight: 400;
    text-transform: capitalize;
    font-style: italic;
    font-family: 'Mansalva', cursive;
}
/* Logo-5 */
.logo-5 h3 {
    color: #e74c3c;
    font-weight: 300;
    font-size: 50px;
    line-height: 0.6;
    font-family: 'Bangers', cursive;
    letter-spacing: 5px;
}
.logo-5 p {
    font-size: 14px;
    margin-left: 0;
    color: #545454;
    font-weight: 400;
    text-transform: capitalize;
    font-style: italic;
    font-family: 'Mansalva', cursive;
    position: relative;
}
.logo-5 p:before {
    position: absolute;
    content: '';
    width: 17px;
    height: 12px;
    background: #545454;
    left: 0px;
    top: 5px;
}
.logo-5 p:after {
    position: absolute;
    content: '';
    width: 17px;
    height: 12px;
    background: #545454;
    right: 0px;
    top: 5px;
}
/* Logo-6 */
.logo-6 h3 {
    color: #e74c3c;

    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 45px;
    letter-spacing: 1px;

    /* font-family: 'Cinzel', serif;
    font-weight: 300;
    font-size: 30px;
     */
    line-height:1.3;
}
.logo-6 h3 span {
    background: #e74c3c;
    color: #fff;
    display: inline-block;
    line-height: 1.3;
    padding: 0 16px;
}
.logo-6 p {
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #34495e;
    font-weight: 400;
    color: #fff;
    padding-left: 5px;
}
/* Logo-7 */
.logo-7 {
    width: 100%;
    display: inline-block;
}
.logo-7 i {
    font-size: 60px;
    display: inline-block;
    float: left;
    margin-right: 5px;
    color: #34495e;
}
.logo-7 .left {
    float: left;
    margin-left: 10px;
    text-align: left;
}
.logo-7 a {
    color: #545454;
}
.logo-7 .left h3 {
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: #e74c3c;
}
.logo-7 .left p {
    text-align: right;
    font-size: 14px;
    color: #919191;
    font-style: italic;
    border-top: 1px dashed #919191;
    letter-spacing: 2px;
    padding-top: 3px;
    font-family: 'Lobster', cursive;
}
/* Logo-8 */
.logo-8 h3 {
    color: #e74c3c;
    font-family: 'Cinzel', serif;
    font-weight: 300;
    font-size: 30px;
    line-height:1.3;
}
.logo-8 h3 span {
    background: ;
    color: #e74c3c;
    display: inline-block;
    line-height: 1.8;
    padding: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    opacity:0.5;
    margin-left:-34px;
}
/* Logo-9 */
.logo-9{
    position:relative;
}
.logo-9 i{
    font-size:80px;
    position:absolute;
    z-index:0;
    text-align:center;
    width:100%;
    left:0;
    top:-10px;
    color:#34495e;
    -webkit-animation:ring 2s ease infinite;
    animation:ring 2s ease infinite;
}
.logo-9 h3{
    font-family: 'Lora', serif;
    font-weight:600;
    text-transform:uppercase;
    font-size:40px;
    position:relative;
    z-index:1;
    color:#e74c3c;
    text-shadow: 3px 3px 0 #fff, -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff;
}
@-webkit-keyframes ring{0%{-webkit-transform:rotate(-15deg);transform:rotate(-15deg)}2%{-webkit-transform:rotate(15deg);transform:rotate(15deg)}4%{-webkit-transform:rotate(-18deg);transform:rotate(-18deg)}6%{-webkit-transform:rotate(18deg);transform:rotate(18deg)}8%{-webkit-transform:rotate(-22deg);transform:rotate(-22deg)}10%{-webkit-transform:rotate(22deg);transform:rotate(22deg)}12%{-webkit-transform:rotate(-18deg);transform:rotate(-18deg)}14%{-webkit-transform:rotate(18deg);transform:rotate(18deg)}16%{-webkit-transform:rotate(-12deg);transform:rotate(-12deg)}18%{-webkit-transform:rotate(12deg);transform:rotate(12deg)}100%,20%{-webkit-transform:rotate(0);transform:rotate(0)}}@keyframes ring{0%{-webkit-transform:rotate(-15deg);transform:rotate(-15deg)}2%{-webkit-transform:rotate(15deg);transform:rotate(15deg)}4%{-webkit-transform:rotate(-18deg);transform:rotate(-18deg)}6%{-webkit-transform:rotate(18deg);transform:rotate(18deg)}8%{-webkit-transform:rotate(-22deg);transform:rotate(-22deg)}10%{-webkit-transform:rotate(22deg);transform:rotate(22deg)}12%{-webkit-transform:rotate(-18deg);transform:rotate(-18deg)}14%{-webkit-transform:rotate(18deg);transform:rotate(18deg)}16%{-webkit-transform:rotate(-12deg);transform:rotate(-12deg)}18%{-webkit-transform:rotate(12deg);transform:rotate(12deg)}100%,20%{-webkit-transform:rotate(0);transform:rotate(0)}}

/* logo-10 */
.logo-10 h3{
  font-family: 'Muli', sans-serif;
  font-weight:100;
  text-transform:uppercase;
  font-size:40px;
  color:#545454;
  line-height:0.3;
}
.logo-10 p{
  color: rgba(255, 255, 255, 0);
  background:#e74c3c;
}
/* Followed */
.follow{
  position:fixed;
  right:20px;
  bottom:10px;
}
.follow img {
    width: 40px;
    height: 40px;
    border: 2px solid #3F51B5;
    border-radius: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    padding: 5px;
    background: #fff;
}


@media only screen and (max-width:736px) {
  body{
      margin:0;
      padding:0;
  }
  .logo-container ul {
      width: 100%;
      text-align: center;
  }
  .logo-container ul li {
      width: 290px;
      margin-left: auto;
      margin-right: auto;
      float: none !important;
  }
  .logo-4 p {
      margin-top: 2px;
  }
  .Logos{
    margin-top:20px;
  }
}

h1.Logos {
    font-weight: 400;
    font-family: 'Bangers', cursive;
    font-size: 40px;
    text-align: center;
    letter-spacing: 3px;
    text-shadow: 2px 2px 0px #2d303a, -2px -2px 0px #2d303a, -2px 2px 0px #2d303a, 2px -2px 0px #2d303a;
    color: #fff;
}
p.para {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    letter-spacing: 30px;
    font-family: 'Lora', serif;
    padding-top: 5px;
    color: #333333;
}

a {
    color: var(--bs-link-color);
    text-decoration: none;
  }